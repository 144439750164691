<template>
  <div>
    <div
      class="flex flex-row space-x-2 md:space-x-0"
      v-if="showSocialNetworksButtons"
    >
      <button
        v-for="socialNetwork in socialNetworks"
        :key="socialNetwork.label"
        class="select-button"
        :class="{ active: socialNetwork.active }"
        :disabled="socialNetwork.disabled"
        @click="onChange(socialNetwork.label)"
      >
        <i
          class=""
          :class="socialNetwork.icon"
          :style="{ color: socialNetwork.color}"
        >
        </i>
        <span class="hidden lg:block">{{ socialNetwork.label }}</span>
      </button>
    </div>
    <div
      v-if="showConnectButton"
    >
      <router-link :to="{ name: 'settings-social-medias' }" class="btn btn-primary">
        {{ $t('action.connect_social_accounts') }}
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, toRefs, unref, watch } from 'vue'
import { useSocialAccounts } from '@/composables/socialAccounts/useSocialAccounts'
import { useRouter } from 'vue-router'
import Swal from 'sweetalert2'
import { useI18n } from 'vue-i18n'
import { useProfile } from '@/composables/profile/useProfile'

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      default: ''
    }
  },

  emits: [
    'update:modelValue'
  ],

  setup (props, {emit}) {
    const router = useRouter()
    const { modelValue } = toRefs(props)
    const availables = ref<string[]>([])
    const isLoaded =  ref<boolean>(false)

    const { t } = useI18n()

    const {
      getAvailables
    } = useSocialAccounts()

    const {
      isFreeTrial,
      isFreeTrialActive
    } = useProfile()

    onMounted( async () => {
      try {
        const items = await getAvailables()
        availables.value = ['instagram', 'facebook', 'linkedin', 'twitter'].filter(item => items.includes(item))
        emit('update:modelValue', unref(availables).length ? unref(availables)[0] : '')
        
        isLoaded.value = true
      } catch (error) {
        isLoaded.value = false
      }
    })

    const showSocialNetworksButtons = computed(() => {
      return unref(isLoaded) && unref(availables).length
    })

    const showConnectButton = computed(() => {
      return unref(isLoaded) 
        && !unref(availables).length
        && (!isFreeTrial.value || isFreeTrial.value && isFreeTrialActive.value)
    })

    watch(isLoaded, (newValue) => {
      if (newValue && unref(showConnectButton)) {
          Swal.fire({
            showCancelButton: true,
            confirmButtonText: t('state.connect_social_accounts.action_confirm'),
            cancelButtonText: t('state.connect_social_accounts.action_cancel'),
            html: t('state.connect_social_accounts.message-html')
          }).then(async ({ value }) => {
            if (value) {
              router.push({ name: 'settings-social-medias' })
            }
          })
      }
    })

    const socialNetworks = computed(() => {
      return [
        {
          icon: 'fab fa-instagram text-lg',
          color: '#e4405f',
          label: 'instagram',
          active: unref(modelValue) === 'instagram',
          disabled: !unref(availables).includes('instagram')
        },
        {
          icon: 'fab fa-facebook-f text',
          color: '#3b5999',
          label: 'facebook',
          active: unref(modelValue) === 'facebook',
          disabled: !unref(availables).includes('facebook')
        },
        {
          icon: 'fab fa-linkedin text-lg',
          color: '#0077b5',
          label: 'linkedin',
          active: unref(modelValue) === 'linkedin',
          disabled: !unref(availables).includes('linkedin')
        },
        {
          icon: 'fab fa-twitter text-lg',
          color: '#55acee',
          label: 'twitter',
          active: unref(modelValue) === 'twitter',
          disabled: !unref(availables).includes('twitter')
        }
      ]
    })

    const onChange = (currentSocialNetwork: string) => {
      emit('update:modelValue', currentSocialNetwork)
    }

    return {
      socialNetworks,
      onChange,
      showSocialNetworksButtons,
      showConnectButton
    }
  }
})
</script>

<style scoped>
@layer components {
  .select-button {
    @apply flex justify-center items-center space-x-2 rounded-full bg-transparent capitalize text-gray-600 text-sm font-bold transition duration-300 ease-in-out;
    @apply py-0 lg:py-2 px-0 lg:px-6 w-8 lg:w-auto h-8 lg:h-12;
  }

  .select-button:disabled {
    @apply cursor-not-allowed;
  }
  .select-button:disabled *,
  .select-button:disabled i {
    @apply text-gray-400 !important;
  }

  .select-button.active {
    @apply bg-blue-100 border border-blue-400 shadow-md;
  }
}
</style>
