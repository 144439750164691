<template>
  <base-modal v-model:open="isActive" hide-footer>
    <template v-slot:button>
      <button
        @click.prevent="toggle"
        class="btn btn-primary"
      >
        <i class="fas fa-comment"></i>
        {{ $t('sentence.comments') }}
      </button>
    </template>

    <div class="card-header">
      <div class="card-title">
        {{ $t('sentence.comments') }}
      </div>
    </div>
    <div class="card-body text-left">
      <div
        class="flex justify-center items-center gap-2 w-full"
        v-if="!isLoaded"
      >
        <i class="fas fa-spinner fa-spin"></i>
        <div>{{ $t('sentence.loading') }}</div>
      </div>
      <ul 
        v-else-if="isLoaded && comments.length"
        class="flex flex-col gap-4">
        <li
          v-for="comment in comments" :key="comment.id"
          class="block"
        >
          <span
            v-if="comment.ownerUsername || comment.ownerFullName"
            class="text-sm font-semibold"
          >
            {{ comment.ownerUsername  || comment.ownerFullName }}
          </span>
          <span class="ml-2">
            {{ comment.text }}
          </span>
          <div class="flex justify-between text-xs text-gray-500 mt-1">
            <!-- <div>{{ comments.likesCount || 0 }} curtidas</div> -->
            <div>{{ toNow(new Date(comment.dateUtc)) }}</div>
          </div>
        </li>
      </ul>
      <div v-else class="flex justify-center items-center w-full">
        Conteúdo sem comentários
      </div>
    </div>
    <div class="card-footer flex justify-end space-x-4">
      <button
        class="btn"
        @click.prevent="toggle"
      >
        {{ $t('action.cancel') }}
      </button>
    </div>

  </base-modal>
</template>

<script>
import { ref, onBeforeMount } from 'vue'
import { useToggle } from '@/composables/useToggle'
import BaseModal from '../base/BaseModal.vue'
import { useAxios } from '@/composables/useAxios'
import { useDateFns } from '@/composables/locale/useDateFns'

export default {
  components: { BaseModal },

  props: {
    postId: {
      type: String,
      required: true
    },
    source: {
      type: String,
      required: true
    },
  },

  setup (props) {
    const comments = ref([])
    const isLoaded = ref(false)

    const { toNow } = useDateFns()

    const {
      isActive,
      toggle
    } = useToggle(false)

    const fetchComments = async () => {
      const { httpFunctions } = await useAxios()
      const request = await httpFunctions.get(`/analytics-requests/comments/${props.source.toLowerCase()}/${props.postId}`)
      if (request.status === 200) {
        comments.value = request.data
      }
      isLoaded.value = true
    }

    onBeforeMount(async () => {
      await fetchComments()
    })

    return {
      isActive,
      toggle,
      comments,
      isLoaded,
      toNow
    }
  }
}
</script>