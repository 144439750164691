<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div>
          <div class="card-title">
            {{ $t('page.analytics.history_of_publication_times.title') }}
          </div>
          <small>
            {{ $t('page.analytics.history_of_publication_times.description') }}
          </small>
        </div>
        <div>
          <base-popover>
            <template v-slot:button>
              <i class="far fa-question-circle text-blue-600"></i>
            </template>
            <div
              class="popover-body"
              v-html="$t('page.analytics.history_of_publication_times.helper-html')"
            />
          </base-popover>
        </div>
      </div>
      <div class="card-body">
        <div v-if="hasData && series.length">
          <apexchart type=heatmap height=260 :options="chartOptions" :series="series" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, toRefs, unref } from 'vue'
import VueApexCharts from "vue3-apexcharts";
import socialNetworkColor from '@/composables/socialNetworks/socialNetworkColor'
import { getHours, getISODay } from 'date-fns'
import { getWeekdays } from '@/composables/locale/getWeekdays'
import BasePopover from '../base/BasePopover.vue';

export default defineComponent({
  components: {
    apexchart: VueApexCharts,
    BasePopover
  },

  props: {
    socialNetworkData: {
      type: Object,
      default: () => ({})
    },
    socialNetworkName: {
      type: String,
      default: undefined
    }
  },

  setup (props) {
    const { socialNetworkData, socialNetworkName } = toRefs(props)

    const hasData = computed(() => {
      return unref(socialNetworkData) && unref(socialNetworkData).bestScoreTimes
    })

    const scoresPerTime = computed(() => {
      const matrix = createMatrix(7, 24)
      if (unref(hasData)) {
        const bestScoreTimes = unref(socialNetworkData).bestScoreTimes
        bestScoreTimes.map((item: any) => {
          const score = item.manycontentScore
          const date = item?.createdAt.toDate()
          const weekDay = getISODay(date)
          const hour = getHours(date)
          if (matrix[weekDay - 1][hour - 1]) {
            matrix[weekDay - 1][hour - 1] = matrix[weekDay - 1][hour - 1] + score
          } else {
            matrix[weekDay - 1][hour - 1] = score
          }
        })
      }

      const maxValue = getMaxValueFromArray2D(matrix)

      // Normalizar matrix para escala de 100
      for (let i = 0; i < matrix.length; i++) {
        const days = matrix[i]
        for (let j = 0; j < days.length; j++) {
          const values = days[j] as number
          if (values) {
            matrix[i][j] = +((values * 100) / maxValue)
          } else {
            matrix[i][j] = 0
          }
        }
      }

      return matrix
    })

    const series = computed(() => {
      const generateChartLine = (arr: any) => {
        const seriesArray: { x: string; y: string; }[] = []
        for (let i = 0; i < arr.length; i++) {
          const x = (i).toString()
          const y = arr[i]
          seriesArray.push({
            x: x,
            y: y
          })
        }
        return seriesArray
      }

      const weekdays = getWeekdays()

      return [
        {
          name: weekdays[0],
          data: generateChartLine(unref(scoresPerTime)[6])
        },
        {
          name: weekdays[6],
          data: generateChartLine(unref(scoresPerTime)[5])
        },
        {
          name: weekdays[5],
          data: generateChartLine(unref(scoresPerTime)[4])
        },
        {
          name: weekdays[4],
          data: generateChartLine(unref(scoresPerTime)[3])
        },
        {
          name: weekdays[3],
          data: generateChartLine(unref(scoresPerTime)[2])
        },
        {
          name: weekdays[2],
          data: generateChartLine(unref(scoresPerTime)[1])
        },
        {
          name: weekdays[1],
          data: generateChartLine(unref(scoresPerTime)[0])
        }
      ]
    })

    const chartOptions = computed(() => {
      return {
        dataLabels: {
          enabled: false
        },
        colors: [socialNetworkColor(unref(socialNetworkName))],
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y: number) {
              if (typeof y !== "undefined") {
                return y.toFixed(0) + "%"
              }
              return y
            }
          }
        }
      }
    })

    const createMatrix = (x: number, y: number) => {
      const result = new Array(x)
      for (let i = 0, length = result.length; i < length; i++) {
        result[i] = new Array(y)
      }
      return result
    }

    const getMaxValueFromArray2D = (mArray: Array<[]>) => {
      // returns maximum of an array
      const getArrayMax = (array: Array<number>) => array.reduce((a, b) => Math.max(a, b), 0)
      // returns maximum of a 2D array
      const getArrayMax2d = (array2d: Array<[]>) => getArrayMax(array2d.map(getArrayMax))
      return getArrayMax2d(mArray)
    }

    return {
      hasData,
      series,
      chartOptions
    }
  }
})
</script>
